import './toast.scss';

const TOAST_TYPE_COLOR = {
  error: '#eb3b5a',
  success: '#00b894',
  default: '#ffff'
};

export default class ToastAlert {
  constructor(alertMessage, type, time) {
    this.alertMessage = alertMessage;
    this.type = type;
    this.time = time;
    this.element = null;
    const element = document.createElement('div');
    element.className = 'cleverpush-toast-notification';
    this.element = element;
    element.style.backgroundColor = TOAST_TYPE_COLOR[type];
    const message = document.createElement('div');
    message.className = `cleverpush-toast-message ${type}`;
    message.textContent = this.alertMessage;

    element.appendChild(message);

    const close = document.createElement('div');
    close.className = `cleverpush-toast-close ${type}`;
    close.innerHTML = '&times';
    element.append(close);

    document.body.appendChild(element);

    setTimeout(() => {
      element.remove();
    }, this.time);

    close.addEventListener('click', () => {
      element.remove();
    });
  }
}
